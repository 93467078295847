import {Button, Col, Form, Input, Row} from "antd";
import classNames from "classnames";
import {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AdminSettingsState} from "src/enums/admin-settings-state.enum";
import {EditOutlined} from '@ant-design/icons';
import {selectTreatmentKitPrices, updateTreatmentKitPrices} from "src/store/organization";
import {DeviceDefinitionDTO, TreatmentKitDTOId} from "@api/mainServiceAPI";
import styles from './CoursesStep.module.scss';
import {ISettingsStep} from "../../ClinicAdminSettingsPage";
import {ReadonlyWarning} from "../ReadonlyWarning/ReadonlyWarning";
import {useTranslation} from "react-i18next";

const TREATMENT_KIT_OPTIONS = [
    {
        text: 'clinic_admin_settings_page.treatment_kit_step.myopia_treatment_kit',
        category: TreatmentKitDTOId.MYOPIA_TREATMENT_KIT
    },
    {
        text: 'clinic_admin_settings_page.treatment_kit_step.strabismus_treatment_kit',
        category: TreatmentKitDTOId.STRABISMUS_TREATMENT_KIT
    },
    {
        text: 'clinic_admin_settings_page.treatment_kit_step.amblyopia_hypermetropia_astigmatism_treatment_kit',
        category: TreatmentKitDTOId.AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT
    },
];

export const TreatmentKitStep = ({isReadonly}: ISettingsStep) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [currentDisplayState, setCurrentDisplayState] = useState<AdminSettingsState>(AdminSettingsState.EMPTY);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const treatmentKitPrices = useSelector(selectTreatmentKitPrices);


    useEffect(() => {
        if (treatmentKitPrices.length || isReadonly) {
            setCurrentDisplayState(AdminSettingsState.FILL);
        }
    }, [treatmentKitPrices, isReadonly])

    const onSubmit = (values: any) => {
        const resultArr: DeviceDefinitionDTO[] = [];
        values.items.forEach((el: any) => {
            resultArr.push({
                deviceDefinitionID: el.deviceDefinitionID,
                price: parseInt(el.price),
            });
        });

        dispatch(updateTreatmentKitPrices(resultArr));
    }

    const onEdit = () => {
        setCurrentDisplayState(AdminSettingsState.EDIT);
    }

    const onCancel = () => {
        setCurrentDisplayState(AdminSettingsState.FILL);
    }

    const handleFieldsChange = () => {
        const fieldsError = form.getFieldsError();
        const hasErrors = fieldsError.some(field => field.errors.length > 0);
        const allFieldsFilled = form.getFieldsValue();
        const isEmptyField = Object.values(allFieldsFilled).some(value => !value);

        setIsFormValid(!hasErrors && !isEmptyField);
    };

    const initValues = useMemo(() => {
        return TREATMENT_KIT_OPTIONS.map((option) => {
            const foundElementsByType = treatmentKitPrices.filter((treatmentKit) => treatmentKit.deviceDefinitionID == option.category);
            const treatmentKitPrice = foundElementsByType.find((el) => el.price!);

            return {
                deviceDefinitionID: option.category,
                price: treatmentKitPrice?.price,
            }
        });
    }, [treatmentKitPrices]);

    const isFillMode = currentDisplayState === AdminSettingsState.FILL;

    return (
        <div className={classNames(styles.wrapper)}>
            <h2 className="d-flex gap-3">
                {t("clinic_admin_settings_page.treatment_kit_step.treatment_kit_costs")}
                {
                    currentDisplayState === AdminSettingsState.FILL && !isReadonly ?
                        <EditOutlined onClick={onEdit}/> :
                        null
                }
            </h2>
            <div className={styles.content}>
                {
                    <Form
                        form={form}
                        className={classNames(styles.coursesForm, isFillMode ? 'filled' : '')}
                        layout="horizontal"
                        onFinish={(value) => onSubmit(value)}
                        onFieldsChange={handleFieldsChange}
                        initialValues={{items: initValues}}
                    >
                        <Row gutter={32} className={styles.consultationRow} style={{border: 'none'}}>
                            <Col style={{flex: '1'}}>
                                <div className={styles.categoryColumn}>
                                    <span>{t("clinic_admin_settings_page.treatment_kit_step.treatment_kit_header")}</span>
                                </div>
                            </Col>
                            <Col>
                                <div className={styles.priceColumn}>
                                    <span>{t("clinic_admin_settings_page.treatment_kit_step.treatment_kit_cost")}</span>
                                </div>
                            </Col>
                        </Row>

                        {
                            TREATMENT_KIT_OPTIONS.map((option, index) =>
                                <Row key={option.category} gutter={40} className={styles.consultationRow}>
                                    <Col style={{flex: '1'}}>
                                        <div>
                                            <span className={styles.consultationTitle}>{t(option.text)}</span>
                                        </div>
                                        <Form.Item
                                            className={styles.priceField}
                                            name={['items', index, 'deviceDefinitionID']}
                                            initialValue={option.category}
                                            hidden
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <div className={styles.pricesWrapper}>
                                            <Form.Item
                                                className={styles.priceField}
                                                name={['items', index, 'price']}
                                                rules={[{required: true, message: t("required_field")}]}
                                            >
                                                {isFillMode ?
                                                    <div className={styles.filledPrice}>
                                                        <span>{form.getFieldValue(['items', index, 'price'])} ₽</span>
                                                    </div>
                                                    :
                                                    <Input
                                                        type={'number'}
                                                        min={1}
                                                        step={0.01}
                                                        max={9999999}
                                                        suffix={<span className={styles.rubIcon}>₽</span>}
                                                    />
                                                }
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        }
                        {
                            !isFillMode && <div className={classNames(styles.actions, 'pt-4')}>
                                <Row justify="space-between" align="middle">
                                    <Col className={classNames(styles.footerLabel, 'pt-3', 'pb-3', 'pr-3')}>
                                        <span
                                            className={styles.footerLabel}>{t("clinic_admin_settings_page.treatment_kit_step.all_costs_required")}</span>
                                    </Col>
                                    <Col className="d-flex gap-3">
                                        {currentDisplayState === AdminSettingsState.EDIT ?
                                            <Button type="primary" onClick={onCancel}>
                                                {t("cancel_button")}
                                            </Button>
                                            : null}
                                        <Button type="primary" htmlType="submit" disabled={!isFormValid}>
                                            {t("save")}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Form>
                }
                <div>
                    {
                        isReadonly ?
                            <ReadonlyWarning
                                text={t("clinic_admin_settings_page.treatment_kit_step.costs_defined_by_clinic")}/> :
                            null
                    }
                </div>
            </div>
        </div>
    );
};