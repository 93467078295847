import {
    approvePatientGadget,
    CarePlanDTOStatus,
    GadgetDeliveryAddressDTO,
    queryPatientGadget,
    TreatmentKitDTO,
    TreatmentKitDTOId,
    TreatmentKitDTOStatus
} from '@api/mainServiceAPI';
import {registerCarePlanPayment, registerCarePlanPayment1, registerCarePlanPayment2} from '@api/paymentServiceAPI';
import {PaymentType} from '@enums';
import {Bubble2, CalendarIcon, CheckIconGreen, CheckSuccess, Dino5, EyeIcon} from '@icons';
import {getPatientCarePlan, selectCarePlan, selectEntityToCheckPayment} from '@slicePatient';
import {selectCurrentProfile} from '@sliceUser';
import {OrderTypes} from '@utils/constants';
import {currencyFormatter} from '@utils/utils';
import {Button, Radio, RadioChangeEvent, Space, Tooltip} from 'antd';
import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {PaymentDirectlyModal} from '../../../../../modals/PaymentDirectlyModal/PaymentDirectlyModal';
import styles from './CourseRecommendedBlock.module.scss';
import {
    selectCustomTheme,
    selectDeliveryAddressActive,
    selectPaymentServiceActive
} from "../../../../../store/organization";
import {DeliveryAddressModal} from "../../../../../modals/DeliveryAddressModal/DeliveryAddressModal";
import miopiaTreatmentKitImg from '../../../../../styles/images/treatment-kit/miopia.png';
import {CustomThemes} from "../../../../../models/custom-themes";
import {createDeliveryAddressString} from "@utils/addressFormatter";
import {t} from "i18next";
import strabismusTreatmentKitImg from "../../../../../styles/images/treatment-kit/strabismus.png";
import ambliopiaHibermetropiaStrabismusTreatmentKitImg
    from "../../../../../styles/images/treatment-kit/ambliopia_hibermetropia_htrabismus.png";

export interface ICourseRecommendedBlockProps {
    withoutWorkspace?: boolean;
}

export const CourseRecommendedBlock = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const carePlan = useSelector(selectCarePlan);
    const currentProfile = useSelector(selectCurrentProfile);
    const entityToCheckPayment = useSelector(selectEntityToCheckPayment);
    const paymentServiceActive = useSelector(selectPaymentServiceActive);
    const deliveryAddressActive = useSelector(selectDeliveryAddressActive);
    const customTheme = useSelector(selectCustomTheme);

    const [courseRecommended, setCourseRecommended] = useState(false);
    const [coursePayed, setCoursePayed] = useState(false);
    const [devicesConfirmed, setDevicesConfirmed] = useState<TreatmentKitDTO[]>();
    const [showPaymentDirectlyModal, setShowPaymentDirectlyModal] = useState(false);
    const [showDeliveryAddressModal, setShowDeliveryAddressModal] = useState(false);
    const [gadget, setGadget] = useState<TreatmentKitDTO>();
    const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.MAIN_COUNTRY);

    useEffect(() => {
        if (carePlan) {
            setCourseRecommended(carePlan.status === CarePlanDTOStatus['on-hold']);
            setCoursePayed(carePlan.status === CarePlanDTOStatus.active);
            setDevicesConfirmed(carePlan?.treatmentKits);
        }
    }, [carePlan]);

    const onInitPaymentForPlan = () => {
        if (currentProfile?.fhirId && carePlan?.fhirId) {
            if (paymentType === PaymentType.MAIN_COUNTRY) {
                registerCarePlanPayment(
                    {
                        carePlanId: carePlan.fhirId!,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                    },
                    {},
                ).then((res) => {
                    window.location.href = res.data.formUrl!;
                });
            } else if (paymentType === PaymentType.PRODAMUS) {
                registerCarePlanPayment1(
                    {
                        carePlanId: carePlan.fhirId!,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-prodamus-fail?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-prodamus-ok?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                        params: {customer_extra: t('payment.prodamus_redirect_info')},
                    },
                    {},
                ).then((res) => {
                    window.location.href = res.data.formUrl!;
                });
            } else if (paymentType == PaymentType.MTS) {
                registerCarePlanPayment2(
                    {
                        carePlanId: carePlan.fhirId!,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-mts-fail?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-mts-ok?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                    },
                    {},
                ).then((res) => {
                    window.location.href = res.data.formUrl!;
                });
            }
        }
    };

    const showPaymentModal = () => {
        setShowPaymentDirectlyModal(true);
    };

    const onConfirmGadget = (gadgetId: TreatmentKitDTOId) => {
        if (currentProfile?.fhirId && carePlan?.fhirId) {
            approvePatientGadget(currentProfile?.fhirId, carePlan?.fhirId, gadgetId).then((result) => {
                if (result) {
                    dispatch(
                        getPatientCarePlan({
                            patientFhirId: currentProfile.fhirId!,
                        }),
                    );
                }
            });
        }
    };

    const openDeliveryAddressModal = (item: TreatmentKitDTO) => {
        setGadget(item);
        setShowDeliveryAddressModal(true);
    };

    const isAllGadgetsConfirmed = (): boolean => {
        let result = true;
        carePlan?.treatmentKits?.forEach((item) => {
            if (item.status === TreatmentKitDTOStatus.PROPOSED) {
                result = false;
            }
        });
        return result;
    };

    const onCreateSchedule = () => {
        navigate('/create-schedule');
    };

    const onOpenGadgetStore = () => {
        console.log('Open gadget store.');
    };

    const onChangePaymentType = (e: RadioChangeEvent) => {
        setPaymentType(e.target.value);
    };

    const handleDeliveryAddressModal = (deliveryAddress: GadgetDeliveryAddressDTO) => {
        if (currentProfile?.fhirId && gadget?.id) {
            queryPatientGadget(currentProfile?.fhirId, gadget?.id, deliveryAddress).then(() => {
                let devices: TreatmentKitDTO[] | undefined = [...devicesConfirmed!];
                if (devices) {
                    devices = devices.map(m => {
                        return m.id === gadget?.id ? {
                            ...m,
                            gadgetDeliveryAddress: deliveryAddress,
                            deliveryStatus: 'CREATED'
                        } : m;
                    });
                    setDevicesConfirmed(devices);
                    setGadget(undefined);
                    setShowDeliveryAddressModal(false);
                }
            });
        }
    }

    return (
        <>
            {/* todo just for checking/notification, that flow is not okay */}
            {!carePlan && <p>{t('userMainPage.courseRecommended.no_appointments')}</p>}

            {courseRecommended && (
                <div className={styles.courseRecommendedBlock}>
                    <div style={{width: '208px'}}>
                        <div className={styles.dino}>
                            <Dino5/>
                            <div className={styles.bubble}>
                                <p className={styles.title}>{t('userMainPage.courseRecommended.new_recommendation')}</p>
                                <Bubble2/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.courseInfo}>
                        <h2>
                            {t(`userMainPage.courseRecommended.${carePlan?.planDefinitionID}`)}{' '}
                            {carePlan?.extended && t('userMainPage.courseRecommended.extended')}
                        </h2>
                        <p className={styles.description}>{t('userMainPage.courseRecommended.description')}</p>
                    </div>

                    {paymentServiceActive ? <div className="mb-4">
                        <Radio.Group value={paymentType} onChange={onChangePaymentType}>
                            <Space direction="vertical">
                                <Radio value={PaymentType.MAIN_COUNTRY} className="mb-3">
                                    {t(`consultation_sign_up.payment_step.${PaymentType.MAIN_COUNTRY}`)}
                                </Radio>
                                <Radio value={PaymentType.PRODAMUS} className="mb-3">
                                    {t(`consultation_sign_up.payment_step.${PaymentType.PRODAMUS}`)}
                                </Radio>
                                <Radio value={PaymentType.MTS} className="mb-3">
                                    {t(`consultation_sign_up.payment_step.${PaymentType.MTS}`)}
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </div> : <></>}

                    <div style={{width: '320px', display: 'flex', flexDirection: 'column', rowGap: '16px'}}>
                        <Tooltip title={
                            'Курс процедур: ' + currencyFormatter(Number(carePlan?.price) - Number(carePlan?.treatmentKitsPrice)) + ' | ' +
                             'Комплекты ОКО: ' + currencyFormatter(Number(carePlan?.treatmentKitsPrice))
                        }>
                            <Button disabled={entityToCheckPayment?.id === carePlan?.fhirId} type="primary"
                                    onClick={onInitPaymentForPlan} size="large">
                                {paymentServiceActive ? t('userMainPage.singleConsultation.toPayBtn') : t('userMainPage.singleConsultation.toAppointmentBtn')}{' '}
                                {paymentServiceActive && (paymentType !== PaymentType.PRODAMUS
                                    ? currencyFormatter(carePlan?.price)
                                    : currencyFormatter(Number(carePlan?.price) * 1.1))}
                            </Button>
                        </Tooltip>
                        {paymentServiceActive ?
                            <Button disabled={entityToCheckPayment?.id === carePlan?.fhirId} type="primary"
                                    onClick={showPaymentModal} size="large">
                                {t('appointment.approvePayment')}
                            </Button> : <></>}
                    </div>
                </div>
            )}
            {coursePayed && (
                <div className={styles.coursePayedBlock}>
                    <div className="d-flex align-items-center">
                        <EyeIcon/>
                        <div style={{marginLeft: '24px'}}>
                            <h3>
                                {t(`userMainPage.courseRecommended.${carePlan?.planDefinitionID}`)}{' '}
                                {carePlan?.extended && t('userMainPage.courseRecommended.extended')}
                            </h3>
                            <p className={styles.description}>{t('userMainPage.courseRecommended.hint_to_start_care_plan')}</p>
                        </div>
                    </div>
                    <div className={styles.payedBlock}>
                        {paymentServiceActive ? <div className="d-flex align-items-center">
                            <p>{t('userMainPage.courseRecommended.payment_success')}</p> <CheckSuccess></CheckSuccess>
                        </div> : <></>}
                    </div>
                </div>
            )}
            {coursePayed && devicesConfirmed && (
                <div className={styles.devicesBlock}>
                    <h2>{t('userMainPage.courseRecommended.oko_complex')}</h2>
                    {devicesConfirmed.map((item) => (
                        <div key={item.id} className={styles.deviceBlock}>
                            <div className="d-flex align-items-start">
                                {item.id === "MYOPIA_TREATMENT_KIT" &&
                                    <img style={{width: '176px', height: '136px'}} src={miopiaTreatmentKitImg}
                                         alt={'Miopia treatment kit'}/>}
                                {item.id === "STRABISMUS_TREATMENT_KIT" &&
                                    <img style={{width: '176px', height: '136px'}} src={strabismusTreatmentKitImg}
                                         alt={'Strabismus treatment kit'}/>}
                                {item.id === "AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT" &&
                                    <img style={{width: '176px', height: '136px'}}
                                         src={ambliopiaHibermetropiaStrabismusTreatmentKitImg}
                                         alt={'Ampliopia hibermetropia strabismus treatment kit'}/>}
                                <div style={{marginLeft: '24px'}}>
                                    <h3>
                                        {t(`consultationSession.courseStep.devices.${item.id}`)}
                                    </h3>
                                    {
                                        <p className={styles.treatmentKitDescription}>{t(`consultationSession.courseStep.devices.description.${item.id}`)}</p>}
                                    {(item.gadgetDeliveryAddress || (deliveryAddressActive && carePlan?.extended && item.status === 'PROPOSED')) && (
                                        <h6 className={styles.deliveryAddress}>{t("userMainPage.courseRecommended.delivery_address")}</h6>)}
                                    {deliveryAddressActive && carePlan?.extended && !item.gadgetDeliveryAddress && (
                                        <p className={styles.addressDefinition}>{t("userMainPage.courseRecommended.delivery_address_not_defined")}</p>)}
                                    {item.gadgetDeliveryAddress && (
                                        <p className={styles.addressDefinition}>{createDeliveryAddressString(t, item.gadgetDeliveryAddress)}</p>)}
                                </div>
                            </div>
                            <div className={styles.confirmBlock}>
                                {item.status === TreatmentKitDTOStatus.PROPOSED ? (
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Tooltip color={CustomThemes[customTheme].primaryBtnHoverBg} placement={'top'}
                                                 title={item.deliveryStatus === 'CREATED' ? t('userMainPage.courseRecommended.treatment_kit_is_not_sent_yet') : ''}>
                                            <Button
                                                disabled={deliveryAddressActive && (item.deliveryStatus === 'CREATED' || item.deliveryStatus === 'DELIVERED')}
                                                type="primary"
                                                onClick={() => !deliveryAddressActive || !carePlan?.extended || item.deliveryStatus !== null ? onConfirmGadget(item.id!) : openDeliveryAddressModal(item)}>
                                                {!deliveryAddressActive || !carePlan?.extended || item.deliveryStatus !== null ? t('userMainPage.courseRecommended.approve_availability') : t('userMainPage.courseRecommended.set_delivery_address')}
                                            </Button>
                                        </Tooltip>
                                    </div>
                                ) : (
                                    item.status === TreatmentKitDTOStatus.APPROVED && (
                                        <p className={classNames(styles.deviceTitle, 'd-flex align-items-center')}>
                                            {t('userMainPage.courseRecommended.availability_approved')} <CheckIconGreen
                                            className="ms-2"/>
                                        </p>
                                    )
                                )}
                            </div>
                        </div>
                    ))}
                    <hr/>
                    <div>
                        <h2>{t('userMainPage.courseRecommended.schedule')}</h2>
                        <div className={styles.deviceBlock}>
                            <div className="d-flex align-items-center">
                                <CalendarIcon/>
                                <div style={{marginLeft: '24px'}}>
                                    <p className={styles.deviceTitle}>{t('userMainPage.courseRecommended.personal_schedule_message')}</p>
                                    <p className={styles.description}>{t('userMainPage.courseRecommended.about_care_plan_message')}</p>
                                </div>
                            </div>
                            <div className={styles.confirmBlock}>
                                <Button disabled={!isAllGadgetsConfirmed()} type="primary"
                                        onClick={() => onCreateSchedule()}>
                                    {t('userMainPage.courseRecommended.create_schedule_button')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <PaymentDirectlyModal
                visible={showPaymentDirectlyModal}
                onClose={() => {
                    setShowPaymentDirectlyModal(false);
                }}
            />

            <DeliveryAddressModal
                visible={showDeliveryAddressModal}
                items={devicesConfirmed}
                item={gadget}
                onClose={() => {
                    setShowDeliveryAddressModal(false);
                }}
                onSave={(deliveryAddress: GadgetDeliveryAddressDTO) => {
                    handleDeliveryAddressModal(deliveryAddress)
                }}
            />
        </>
    );
};
