import styles from './ClinicAdminSettingsPage.module.scss';
import classNames from "classnames";
import { Col, Row } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { ProgressBar } from 'src/components/ProgressBar/ProgressBar';
import { RequisitesStep } from './components/RequisitesStep/RequisitesStep';
import { useDispatch, useSelector } from 'react-redux';
import { selectStep, selectSteps, setActiveStep, setInitSettings, setReadonlyMode } from 'src/store/organization';
import { SettingsStepType } from 'src/enums/admin-settings-type.enum';
import { DocumentsStep } from './components/DocumentsStep/DocumentsStep';
import { useEffect, useMemo, useState } from 'react';
import { ConsultationsStep } from './components/ConsultationsStep/ConsultationsStep';
import { CoursesStep } from './components/CoursesStep/CoursesStep';
import { PreferencesStep } from './components/PreferenceSettingsStep/PreferencesStep';
import { UserRole, selectAuthUser } from '@sliceUser';
import { LockIcon, StepDisabledIcon, StepDoneIcon, StepSignedIcon } from '@icons';
import {useTranslation} from "react-i18next";
import {TreatmentKitStep} from "./components/TreatmentKitStep/TreatmentKitStep";

export interface ISettingsStep {
    isReadonly?: boolean
}
//render: (props: IStepProps) => <RequisitesStep {...props} />
const stepsComponents = [
    {
        type: SettingsStepType.REQUISITE,
        title: "clinic_admin_settings_page.requisites",
        render: (props: ISettingsStep) => <RequisitesStep {...props} />
    },
    {
        type: SettingsStepType.DOCUMENTS,
        title: "clinic_admin_settings_page.documents",
        render: (props: ISettingsStep) => <DocumentsStep {...props} />
    },
    {
        type: SettingsStepType.COST_CONSULTATIONS,
        title: "clinic_admin_settings_page.consultation_costs",
        render: (props: ISettingsStep) => <ConsultationsStep {...props} />
    },
    {
        type: SettingsStepType.COST_COURSES,
        title: "clinic_admin_settings_page.course_step.course_costs",
        render: (props: ISettingsStep) => <CoursesStep {...props} />
    },
    {
        type: SettingsStepType.COST_TREATMENT_KITS,
        title: "clinic_admin_settings_page.treatment_kit_step.treatment_kit_costs",
        render: (props: ISettingsStep) => <TreatmentKitStep {...props} />
    },
    {
        type: SettingsStepType.PREFERENCES,
        title: "clinic_admin_settings_page.preference_settings.logo_and_colors",
        render: (props: ISettingsStep) => <PreferencesStep {...props} />
    },
];

export const ClinicAdminSettingsPage = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(true);
    const authUser = useSelector(selectAuthUser);
    const currentStep = useSelector(selectStep);
    const steps = useSelector(selectSteps);
    const dispatch = useDispatch();

    const onStepMenuClick = (type: SettingsStepType) => {
        dispatch(setActiveStep(type));
    }

    useEffect(() => {
        const initializeSettings = async () => {
            setLoading(true);
            await dispatch(setInitSettings());
            setLoading(false);
        };

        initializeSettings();

        const readonlyMode = authUser?.role !== UserRole.Administrator;
        dispatch(setReadonlyMode(readonlyMode));
        
    }, [authUser]);

    const stepComponentElement = useMemo(() => {
        return stepsComponents.find(step => step.type == currentStep?.type);
    }, [currentStep]);

    return (
        <div className={classNames(styles.wrapper, 'mt-5')}>
            <div className={styles.contentWrapper}>
                <Row gutter={12}>
                    <Col>
                        <h1>{t("clinic_admin_settings_page.clinic_settings")}</h1>
                    </Col>
                </Row>
                <Row gutter={72}>
                    <Col style={{ width: '312px' }}>
                        <div className={styles.navMenu}>
                            <ul className={classNames(styles.menu, 'mb-4')}>
                                {
                                    stepsComponents.map((option) => {
                                        const cStep = steps.find(step => step.type == option.type);
                                        return cStep && <li key={t(option.title)} onClick={() => onStepMenuClick(option.type)}
                                                className={
                                                    classNames(cStep.active ? styles.active: '', 
                                                               cStep.processed ? styles.processed : '', 
                                                               cStep.readonly ? styles.readonly : '',
                                                               'd-flex', 'gap-3')}
                                                >
                                                {
                                                    cStep.readonly ? <StepSignedIcon className={styles.stepIcon} /> :
                                                        cStep.processed ? <StepDoneIcon className={styles.stepIcon} /> :
                                                            <CheckCircleFilled className={styles.stepIcon} />
                                                }

                                                <span>
                                                    {t(option.title)}
                                                    {
                                                        cStep.readonly ? 
                                                        <LockIcon className={classNames(styles.lockIcon, 'ms-3 position-absolute')} /> : 
                                                        null
                                                    }
                                                </span>
                                            </li>
                                        }  
                                    )
                                }
                            </ul>
                            <div className={classNames(styles.progressBar, 'p-4')}>
                                <ProgressBar processed={steps.reduce((acc, el) => el.processed ? acc + 1 : acc, 0)} steps={6} />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        {
                            loading ? null : 
                            stepComponentElement && stepComponentElement.render({ isReadonly: currentStep?.readonly ?? false })
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
};
