import { UserDirectDocumentDTO } from "@api/documentServiceAPI";
import {
    CarePlanProductPriceDTO,
    DeviceDefinitionDTO,
    OrganizationCUDTO,
    PractitionerProductPriceDTO
} from "@api/mainServiceAPI";
import { SettingsStepType } from "src/enums/admin-settings-type.enum";
import { CustomThemeNames } from "src/models/custom-themes";

export interface IAdminSettingsStep {
    type: SettingsStepType,
    processed: boolean,
    active: boolean,
    readonly: boolean
}

export interface IOrganizationState {
    steps: IAdminSettingsStep[],
    requisite: OrganizationCUDTO | null,
    documents: UserDirectDocumentDTO[],
    consultationPrices: PractitionerProductPriceDTO[],
    coursePrices: CarePlanProductPriceDTO[],
    treatmentKitPrices: DeviceDefinitionDTO[],
    theme: CustomThemeNames,
    logoURL: string,
    tempLogoUrl: string,
    preferencesEditing: boolean,
    paymentServiceActive: boolean,
    deliveryAddressActive: boolean,
    signatureFilesActive: boolean,
    hideSpecificConsultationParams: boolean,
}

export const initialOrganizationState: IOrganizationState = {
    steps: [{
        type: SettingsStepType.REQUISITE,
        processed: false,
        active: true,
        readonly: false
    },{
        type: SettingsStepType.DOCUMENTS,
        processed: false,
        active: false,
        readonly: false
    },{
        type: SettingsStepType.COST_CONSULTATIONS,
        processed: false,
        active: false,
        readonly: false
    },
    {
        type: SettingsStepType.COST_COURSES,
        processed: false,
        active: false,
        readonly: false
    },
    {
        type: SettingsStepType.COST_TREATMENT_KITS,
        processed: false,
        active: false,
        readonly: false
    },
    {
        type: SettingsStepType.PREFERENCES,
        processed: false,
        active: false,
        readonly: false
    }],
    requisite: null,
    documents: [],
    consultationPrices: [],
    coursePrices: [],
    treatmentKitPrices: [],
    theme: 'default',
    logoURL: '',
    tempLogoUrl: '',
    preferencesEditing: false,
    paymentServiceActive: true,
    deliveryAddressActive: false,
    signatureFilesActive: true,
    hideSpecificConsultationParams: false,
};
